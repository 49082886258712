var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blades',{attrs:{"maximizedCount":10,"anchorBlades":[
        { bladeName: 'stock-notification-configurations' }, 
        { bladeName: 'order-invoicing-notification-configurations' },
        { bladeName: 'ordering-notification-configurations' },
        { bladeName: 'courier-notification-configurations' }]},scopedSlots:_vm._u([{key:"default",fn:function(bladesData){return [_c('BT-Blade-Items',{attrs:{"addBladeName":"stock-notification-configuration","bladeName":"stock-notification-configurations","bladesData":bladesData,"canAdd":"","canDelete":"","canSearchLocal":"","flexColumn":"","headers":[
                { text: 'Name', value: 'configurationName', title: 1, searchable: true },
                { text: 'Status', value: 'status', subtitle: 1 }],"navigation":"stock-notification-configurations","title":"Stock","useServerPagination":""}}),_c('BT-Blade-Items',{attrs:{"addBladeName":"order-invoicing-notification-configuration","bladeName":"order-invoicing-notification-configurations","bladesData":bladesData,"canAdd":"","canDelete":"","canSearchLocal":"","flexColumn":"","headers":[
                { text: 'Name', value: 'configurationName', title: 1, searchable: true },
                { text: 'Status', value: 'status', subtitle: 1 }],"navigation":"order-invoicing-notification-configurations","title":"Order Invoicing","useServerPagination":""}}),_c('BT-Blade-Items',{attrs:{"addBladeName":"ordering-notification-configuration","bladeName":"ordering-notification-configurations","bladesData":bladesData,"canAdd":"","canDelete":"","canSearchLocal":"","flexColumn":"","headers":[
                { text: 'Name', value: 'configurationName', title: 1, searchable: true },
                { text: 'Status', value: 'status', subtitle: 1 }],"navigation":"ordering-notification-configurations","title":"Ordering","useServerPagination":""}}),_c('BT-Blade-Items',{attrs:{"addBladeName":"courier-notification-configuration","bladeName":"courier-notification-configurations","bladesData":bladesData,"canAdd":"","canDelete":"","canSearchLocal":"","flexColumn":"","headers":[
                { text: 'Name', value: 'configurationName', title: 1, searchable: true },
                { text: 'Status', value: 'status', subtitle: 1 }],"navigation":"courier-notification-configurations","title":"Courier","useServerPagination":""}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }