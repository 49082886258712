<template>
    <BT-Blades
        :maximizedCount="10"
        :anchorBlades="[
            { bladeName: 'stock-notification-configurations' }, 
            { bladeName: 'order-invoicing-notification-configurations' },
            { bladeName: 'ordering-notification-configurations' },
            { bladeName: 'courier-notification-configurations' }]">
        <template v-slot="bladesData">
            <BT-Blade-Items
                addBladeName="stock-notification-configuration"
                bladeName="stock-notification-configurations"
                :bladesData="bladesData"
                canAdd
                canDelete
                canSearchLocal
                flexColumn
                :headers="[
                    { text: 'Name', value: 'configurationName', title: 1, searchable: true },
                    { text: 'Status', value: 'status', subtitle: 1 }]"
                navigation="stock-notification-configurations"
                title="Stock"
                useServerPagination />
            
            <BT-Blade-Items
                addBladeName="order-invoicing-notification-configuration"
                bladeName="order-invoicing-notification-configurations"
                :bladesData="bladesData"
                canAdd
                canDelete
                canSearchLocal
                flexColumn
                :headers="[
                    { text: 'Name', value: 'configurationName', title: 1, searchable: true },
                    { text: 'Status', value: 'status', subtitle: 1 }]"
                navigation="order-invoicing-notification-configurations"
                title="Order Invoicing"
                useServerPagination />
                
            <BT-Blade-Items
                addBladeName="ordering-notification-configuration"
                bladeName="ordering-notification-configurations"
                :bladesData="bladesData"
                canAdd
                canDelete
                canSearchLocal
                flexColumn
                :headers="[
                    { text: 'Name', value: 'configurationName', title: 1, searchable: true },
                    { text: 'Status', value: 'status', subtitle: 1 }]"
                navigation="ordering-notification-configurations"
                title="Ordering"
                useServerPagination />

                <BT-Blade-Items
                addBladeName="courier-notification-configuration"
                bladeName="courier-notification-configurations"
                :bladesData="bladesData"
                canAdd
                canDelete
                canSearchLocal
                flexColumn
                :headers="[
                    { text: 'Name', value: 'configurationName', title: 1, searchable: true },
                    { text: 'Status', value: 'status', subtitle: 1 }]"
                navigation="courier-notification-configurations"
                title="Courier"
                useServerPagination />
        </template>
    </BT-Blades>
</template>

<script>
export default {
    name: 'Notification Configurations',
}
</script>